import { useEffect, useState } from "react";
import Note from "./note";
import { NOTE_ENDPOINTS } from "../../../utils/api";
import jwtInterceptor from "../../../service/jwtInterceptor";
import { useToast } from "../../../Context/ToastContext";
import PropTypes from "prop-types";

const ContactNote = ({contact, onUpdate, createNote }) => {
    const [noteData, setNoteData] = useState([]);
    const toaster = useToast()
  
    useEffect(() => {
      jwtInterceptor.get(NOTE_ENDPOINTS.getByContactId.replace(":contactId", contact._id)).then((response) => {
        if(response.status.toString().startsWith("200")){
          setNoteData(response.data.data.records);
  
        }else{
          toaster.notifyError(response.data.message)
        }
  
      }).catch((error) => toaster.notifyError(error))
  
  
    }, []);

    return (
        <Note notes={noteData} createNote={createNote} onUpdate={onUpdate}/>
    )
}

ContactNote.propTypes = {
    contact: PropTypes.any,
    onUpdate: PropTypes.func,
    createNote: PropTypes.func
}

export default ContactNote;