import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {landsInteractionsService} from "../service/crm/LandsInteractionsService";
import {useToast} from "../Context/ToastContext";

export const ChangeArchiveActionBox = ({row, open, onClose}) => {

    const [loadingConfirmModal, setLoadingConfirmModal] = useState(false)
    const deleteFavoriteMessage = "Souhaitez-vous enlever de vos archives ?";
    const addFavoriteMessage = "Voulez-vous l’ajouter dans vos archives ?";
    const toaster = useToast()

    const handleCloseChangeIsArchived = (event) => {
        onClose(event)
    };

    const getUpdateArchiveMessage = () => {
        return (row.landsInteractions?.isArchived) ? deleteFavoriteMessage : addFavoriteMessage;
    }

    const confirmUpdateArchive = (event) => {
        setLoadingConfirmModal(true)
        const isCurrentlyArchive = row.landsInteractions?.isArchived;

        landsInteractionsService.updateArchive(row.id)
            .then((landsInteractions) => {
                if (landsInteractions) {
                    row.landsInteractions = landsInteractions;
                    if (isCurrentlyArchive) {
                        toaster.notifySuccess("La parcelle a été retirée de vos archive avec succès !");
                    } else {
                        toaster.notifySuccess("La parcelle a été ajoutée à vos archive avec succès !");
                    }
                } else {
                    toaster.notifyError("Problème durant la récupération de la réponse");
                }
            })
            .catch(error => toaster.notifyError("Erreur WS : " + error.message))
            .finally(() => {
                setLoadingConfirmModal(false)
                handleCloseChangeIsArchived(event)
            })
    }


    return (
        <>
            <Dialog
                open={open}
                onClose={handleCloseChangeIsArchived}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Mise à jour archive"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {getUpdateArchiveMessage()}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseChangeIsArchived}>Annuler</Button>
                    {loadingConfirmModal ? (
                        <CircularProgress color="secondary" />
                    ) : (
                        <Button onClick={confirmUpdateArchive} autoFocus>Confirmer</Button>
                    )}
                </DialogActions>
            </Dialog>
        </>
    )
}