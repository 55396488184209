import React, { createContext, useContext, useState } from 'react';

const FavoriteContext = createContext(null);

export const FavoriteProvider = ({ children }) => {
  const [favorites, setFavorites] = useState({});

  const updateFavorite = (landId, isFavorite) => {
    setFavorites(prev => ({
      ...prev,
      [landId]: isFavorite
    }));
  };

  const getFavoriteStatus = (landId) => {
    return favorites[landId] || false;
  };

  return (
    <FavoriteContext.Provider value={{ getFavoriteStatus, updateFavorite }}>
      {children}
    </FavoriteContext.Provider>
  );
};

export const useFavorites = () => {
  const context = useContext(FavoriteContext);
  if (!context) {
    throw new Error('useFavorites must be used within a FavoriteProvider');
  }
  return context;
};