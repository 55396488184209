import React from 'react';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import { Box } from '@mui/material';
import PropTypes from "prop-types";

const hexToRgb = (hex) => {
     hex = hex.replace(/^#/, '');

     let bigint = parseInt(hex, 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;

    return `${r}, ${g}, ${b}`;
};

const CustomBtn = ({ label , bgColor , iconBg , icon , mR , mL , click, textColor , fontSizes, btnHeight, styles, noShadow , fullWidth }) => {

    const rgbColor = hexToRgb(bgColor.replace(/^#/, ''));

    return (
        <Box sx={{marginRight: mR , marginLeft: mL , paddingBlock: styles, width: fullWidth ? '100%' : 'auto'}} onClick={click}>
        <Chip 
            sx={{ background: bgColor, fontWeight: "400", height: btnHeight , color: textColor ?? "#fff", fontSize: fontSizes ?? "15px" , cursor: "pointer" , boxShadow: noShadow ? '' :  '0px 4px 4px 0px rgba(0, 0, 0, 0.25);',  width: fullWidth ? '100%' : 'inherit', '&:hover': {
                background: `rgba(${rgbColor}, 0.7)`  
            } }} 
            label={label} 
            avatar={<Avatar sx={{ background: iconBg }}><span style={{ color: "#fff", fontSize: "25px", display: "flex", alignItems: "center" }}>{icon}</span></Avatar>}
            clickable 
        />
        </Box>
    );
}

CustomBtn.propTypes = {
    label: PropTypes.string,
    bgColor: PropTypes.string,
    iconBg: PropTypes.any,
    icon: PropTypes.any,
    mR: PropTypes.any,
    mL: PropTypes.any,
    click: PropTypes.any,
    textColor: PropTypes.string,
    fontSizes: PropTypes.any,
    btnHeight: PropTypes.any,
    styles: PropTypes.any,
    noShadow: PropTypes.any,
    fullWidth: PropTypes.any
}

export default CustomBtn;




