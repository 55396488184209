// variables de style 

export const detailparcelleStyles = {
    mapsLayout: {
      // height: "500px",
      overflow: 'hidden',
      objectFit: 'cover',
      zIndex: 0,
      position: "inherit",
      background: '#fff',
    },
    fontWeightTitle: {
      fontWeight: "600"
    },
    blueLight: '#299CDB',
    graylight: 'rgb(142, 142, 142)',
    brown: '#363636',
    red: '#E31414',
    white: '#fff',
    validateBtn: {
      background: '#299CDB',
      width: 'auto',
      borderRadius: "8px",
      border: "none",
      paddingInline: "15px"
    },
    textBlueLight: {
      color: '#299CDB'
    },
    logoWidth: {
      height: '30px'
    },
    grayBg: {
      background: '#FAFAFA'
    },
    mt1px: {
      marginTop: '2px'
    },
    noteBorder: {
      border: '1px solid rgba(217, 217, 217, 0.42)'
    },
    inputColor: {
      background: '#F3F7F9'
    },
    inTitle: {
      color: '#757575'
    },
    InputedStyle: {
      height: "37px",
      backgroundColor: "#F3F7F9",
      border: '1px solid #ced4da',
      borderRadius: "5px",
    },
    cellWdth10p: {
      maxWidth: '150px',
      width: '100%'
    },
    cellWdth: {
      maxWidth: '100%',
      // maxWidth: '500px',
      // minWidth: '200px',
      width: '100%',
    },
    responsivePaddingCell:{
      padding: {
        xl:"1.5rem !important",
        lg:"1.5rem !important",
        md:"1.5rem !important",
        sm:"1.5rem !important",
        xs:"0 !important",
      }
    },
    innCellHeight: {
      height: "25%"
    },
    transWdth: {
      maxWidth: '721px',
      width: '100%'
    },
    accordions: {
      borderRadius: "4px 4px 0px 0px",
      boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.13)",
      display: "flex",
      flexDirection: "row-reverse",
      "& > .MuiAccordionSummary-content " : {
        flexWrap:{
          xl: "nowrap",
          lg: "nowrap",
          md: "nowrap",
          sm: "wrap",
          xs: "wrap"
        },
        "& > p" : {
          width:{
            xl: "auto",
            lg: "auto",
            md: "auto",
            sm: "100%",
            xs: "100%"
          },
          textAlign:{
            xl: "auto",
            lg: "auto",
            md: "auto",
            sm: "left",
            xs: "left"
          },
          ml:5,
          paddingBlock: 1
        }
      }
    },
    mobileTitleSpacing: {
      ml:{
        xl: 5,
        lg: 5,
        md: 5,
        sm: 0,
        xs: 0
      },
    },
    flexWrapMobile:{
      flexWrap: {
        xl: "nowrap",
        lg: "nowrap",
        md: "nowrap",
        sm: "wrap",
        xs: "wrap"
      }
    },
    w100mobile:{ width :
      {
        xl: "inherit",
        lg: "inherit",
        md: "inherit",
        sm: "100%!important",
        xs: "100%!important"
    },
    wordBreak: "break-word"
  }
};
  