import Sidebar from "../component/Sidebar"
import Header from '../component/Header'
import { useState } from "react"
import SidebarMap from "../component/sidebarMap"
import useCurrentPath from "../hooks/useCurrentPath"
import { SidebarProvider } from "../Context/SidebarContext"

export const MainContainer = ({ children }) => {
  const [open, setOpen] = useState(true);
  const currentPath = useCurrentPath();


  const handleViewSidebar = () => {
    setOpen(!open)
  }
  return (
    <SidebarProvider>
      <SidebarMap currentPath={currentPath}></SidebarMap>
      <Header isOpen={open} onClick={handleViewSidebar}>
        {children}
      </Header>
    </SidebarProvider>
  )
}
