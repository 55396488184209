import React from "react";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import Form from "react-bootstrap/Form";
import { Box, Card } from "@mui/material";
import PropTypes from "prop-types";

const blueLight = "#299CDB";
const validateBtns = {
    background: blueLight,
    height: "40px",
    width: "auto",
    borderRadius: "8px",
    border: "none",
    marginTop: "20px"
  };
  const fontWeightTitles = {
    fontWeight: "600",
  };

const CustomFilters = ({ labelGroups, labelControlValues, classes, onValidate, id }) => {

    const searchByFilter = () => {
        onValidate()
    }

  return (
    <Card className={classes} style={{ padding: "20px", backgroundColor: "#fff", height: "100%" }}>
      <Box>
        <Form>
          {labelGroups.map((group, index) => (
            <Form.Group className="my-3" controlId={group.id} key={`${group.label}-${group.id}`}>
              <Form.Label>{group.label}</Form.Label>
              <Form.Control
                type={group.type}
                placeholder={group.placeholder}
                value={labelControlValues[group.id]}
                onChange={(e) => group.onChange(e.target.value)}
              />
              {/* <TextField
                  id={group.placeholder}
                  label={group.placeholder}
                  fullWidth
                  type={group.type}
                  placeholder={group.placeholder}
                  value={labelControlValues[group.id]}
                  onChange={(e) => group.onChange(e.target.value)}
                /> */}
            </Form.Group>

          ))}
          <Button style={{...validateBtns, ...fontWeightTitles}} onClick={searchByFilter} >
            RECHERCHER
          </Button>
        </Form>
      </Box>
    </Card>
  );
};

CustomFilters.propTypes = {
    labelGroups: PropTypes.array,
    labelControlValues: PropTypes.array,
    classes: PropTypes.any,
    onValidate: PropTypes.func,
    id: PropTypes.number | PropTypes.string,
}
export default CustomFilters;
