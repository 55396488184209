import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";
import React, {useState} from "react";

export const AppCustomConfirmationDialog = ({open, title, bodyMessage, onAccept, onDecline, onClose}) => {
    const [loadingConfirmModal, setLoadingConfirmModal] = useState(false)

    const handleOnAccept = () => {
        setLoadingConfirmModal(true)
        onAccept()
        onClose()
        setLoadingConfirmModal(false)
    }
    const handleOnDecline = async () => {
        setLoadingConfirmModal(true)
        await onDecline()
        setLoadingConfirmModal(false)

    }

    const handleOnClose = () => {
        onClose()
    }
    return (
        <Dialog
            open={open}
            onClose={handleOnClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"

        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {bodyMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOnDecline}>annuler</Button>
                { loadingConfirmModal ?
                    <CircularProgress color="secondary" /> :
                    <Button onClick={handleOnAccept} autoFocus>confirmer</Button>
                }
            </DialogActions>
        </Dialog>
    )
}
