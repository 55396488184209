import React from "react";
import { useApplication } from "../../../hooks/UseApplication";
import {
  Box,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@mui/material";
import { detailparcelleStyles } from "./styles";
import jwtInterceptor from "../../../service/jwtInterceptor";
import { useState, useEffect } from "react";
import { API_URL } from "@utils/constants";

/*****Ui icons */
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useToast } from "../../../Context/ToastContext";
import { useTransactionsAndFaos } from "../../../Context/crm/TransactionsAndFaosContext";

const EnqueteDetail = ({ details }) => {
  const { faos, setFaos } = useTransactionsAndFaos();
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = React.useState("panel1");
  const handleChanges = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const toaster = useToast();

  const fetchData = async () => {
    setLoading(true);
    if (details) {
      try {
        const landId = details.land_id;
        console.log("inside fetchData enquetes");
        const response = await jwtInterceptor.get(
          `${API_URL}/faos/land/${landId}`,
          {
            size: 2000,
            page: 1,
            region: "all",
          }
        );

        const data = response.data.data.results;
        const matchingEnquetes = await data.filter(
          (enq) => enq._source.lands_id === landId.toString()
        );
        console.log("matching enquetes : ", matchingEnquetes);
        setFaos(matchingEnquetes);
      } catch (error) {
        console.error("Erreur lors de la requête API :", error);
        toaster.notifyError("erreur : " + error.message);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [details]);

  useEffect(() => {
    setLoading(false);
  }, [faos]);

  const renderFaos = (faos) => {
    if (faos && faos.length > 0) {
      return faos.map((fao) => renderFaosLine(fao));
    }

    return <Typography variant="body1">Pas d'enquête disponible</Typography>;
  };

  const renderFaosLine = (enquete) => {
    return (
      <Accordion
        expanded={expanded === enquete._id}
        onChange={handleChanges(enquete._id)}
        key={enquete._id}
        sx={{ pb: 0 }}
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={<ExpandMoreIcon />}
          sx={detailparcelleStyles.accordions}
        >
          <Typography
            sx={{
              width: "33.3%",
              textAlign: "start",
              ...detailparcelleStyles.mobileTitleSpacing,
              color: "#299CDB",
              fontWeight: "bold",
            }}
          >
            {enquete._source.type_name}
          </Typography>
          <Typography sx={{ width: "33.3%", textAlign: "center" }}>
            {enquete._source.work_type}
          </Typography>
          <Typography
            sx={{
              width: "33.3%",
              textAlign: "right",
              color: "#299CDB",
              fontWeight: "bold",
            }}
          >
            {enquete._source.inquiry_start_date}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ background: "#FAFAFA" }}>
          <Box className="col-12 p-2">
            <Box
              className="d-flex justify-content-between "
              sx={{
                ...detailparcelleStyles.grayBg,
                ...detailparcelleStyles.flexWrapMobile,
                ...detailparcelleStyles.responsivePaddingCell,
              }}
            >
              <Box
                className="mx-2 w-50"
                sx={{ ...detailparcelleStyles.w100mobile }}
              >
                <p>
                  <span
                    style={{
                      color: detailparcelleStyles.graylight,
                      ...detailparcelleStyles.fontWeightTitle,
                    }}
                  >
                    Code du dossier :
                  </span>{" "}
                  <br />
                  {enquete._source.fao_code_number}
                </p>
                <p>
                  <span
                    style={{
                      color: detailparcelleStyles.graylight,
                      ...detailparcelleStyles.fontWeightTitle,
                    }}
                  >
                    Juridiction :
                  </span>{" "}
                  {enquete._source.jurisdiction_name}
                </p>
                <p>
                  <span
                    style={{
                      color: detailparcelleStyles.graylight,
                      ...detailparcelleStyles.fontWeightTitle,
                    }}
                  >
                    Propriétaire :
                  </span>{" "}
                  {enquete._source.owner}
                </p>
                <p>
                  <span
                    style={{
                      color: detailparcelleStyles.graylight,
                      ...detailparcelleStyles.fontWeightTitle,
                    }}
                  >
                    Architecte :
                  </span>{" "}
                  {enquete._source.architect}
                </p>
                <p>
                  <span
                    style={{
                      color: detailparcelleStyles.graylight,
                      ...detailparcelleStyles.fontWeightTitle,
                    }}
                  >
                    Region :
                  </span>{" "}
                  {enquete._source.region}
                </p>
                <p>
                  <span
                    style={{
                      color: detailparcelleStyles.graylight,
                      ...detailparcelleStyles.fontWeightTitle,
                    }}
                  >
                    Commune :
                  </span>{" "}
                  {enquete._source.city}
                </p>
              </Box>
              <Box
                className="mx-2 w-50"
                sx={{ ...detailparcelleStyles.w100mobile }}
              >
                <p>
                  <span
                    style={{
                      color: detailparcelleStyles.graylight,
                      ...detailparcelleStyles.fontWeightTitle,
                    }}
                  >
                    Description des travaux :
                  </span>{" "}
                  <br />
                  {enquete._source.work_description}
                </p>
                <p>
                  <span
                    style={{
                      color: detailparcelleStyles.graylight,
                      ...detailparcelleStyles.fontWeightTitle,
                    }}
                  >
                    CAMAC :
                  </span>{" "}
                  {enquete._source.camac_code_number}
                </p>
                <p>
                  <span
                    style={{
                      color: detailparcelleStyles.graylight,
                      ...detailparcelleStyles.fontWeightTitle,
                    }}
                  >
                    Mandataire :
                  </span>{" "}
                  {enquete._source.result}
                </p>
                <p>
                  <span
                    style={{
                      color: detailparcelleStyles.graylight,
                      ...detailparcelleStyles.fontWeightTitle,
                    }}
                  >
                    Adresse :
                  </span>{" "}
                  {enquete._source.address}
                </p>
                <p>
                  <span
                    style={{
                      color: detailparcelleStyles.graylight,
                      ...detailparcelleStyles.fontWeightTitle,
                    }}
                  >
                    Liens :
                  </span>{" "}
                  <Link
                    to={enquete._source.url_official}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {enquete._source.url_official}
                  </Link>
                </p>
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <Box className="Layout bg-white my-4 rounded p-4" id="enquete">
      <Typography variant="h6" style={detailparcelleStyles.fontWeightTitle}>
        ENQUÊTES
      </Typography>
      <Box sx={{ p: 1, mt: 2 }}>
        {loading ? <CircularProgress /> : renderFaos(faos)}
      </Box>
    </Box>
  );
};

export default EnqueteDetail;
