import React, { useState, useEffect } from "react";
import { Box, Autocomplete, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import { ajouterAffaire } from "../../../indexedDb/index";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useToast } from "../../../Context/ToastContext";
import { useContact } from "../../../Context/crm/ContactContext";
import { z } from "zod";
import { formatCurrency } from "../../../utils/helper";
import {setDefaults} from "react-i18next";
import PropTypes from "prop-types";

const CreateAffaire = ({closeIt, opportunity}) => {

  console.log("opportunity inside createAffaire", opportunity)
  const {contact} = useContact()
  console.log("contact inside createAffaire", contact)
  const [id, setId] = useState(null)
  const [nomAffaire, setNomAffaire] = useState("");
  const [selectedParcelle, setSelectedParcelle] = useState({label: "", value: null});
  const [montant, setMontant] = useState("");
  const [formatedAmount, setFormatedAmount] = useState("");
  const [description, setDescription] = useState("");
  const [etapes, setEtapes] = useState(null);
  const [errors, setErrors] = useState([])
  const isUpdate = opportunity && true

  const toaster = useToast()

  const successtoast = () => toaster.notifySuccess((opportunity)? "Affaire modifiée avec succès" : "Affaire crée avec succès");

  const initializeSelectedParcelle = (opportunityMin) => {
    const matchedOpportunities = contact.opportunities.filter((contactOppurtunity) => contactOppurtunity._id === opportunityMin._id)

    if(matchedOpportunities.length > 0){
      const contactOpportunity = matchedOpportunities.shift()
      setSelectedParcelle({label : contactOpportunity.land.resume.code_number, value : contactOpportunity.land._id})
    }else {
      console.log("you provide an opportunity but we cant find it in the contact provided")
      toaster.notifyError("Veuillez contacter votre admnistrateur, erreur durant création/modification affaire")
    }
  }

  const initializeDefaultStep = (opportunityMin) => {
    if(opportunityMin?.status) {
      const matchedStep = defaultSteps.filter((defaultStep) => defaultStep.value === opportunityMin.description)
      if(matchedStep.length === 0) {
        setDefaults(defaultSteps.concat([{label : opportunityMin.status, value : opportunityMin.status}]))
      }

      setEtapes(opportunityMin.status)
    }
  }

  const initializeDefaultDataValues = (opportunityMin) => {
    setId(opportunityMin._id ?? null)
    setNomAffaire(opportunityMin.name ?? nomAffaire)
    setDescription(opportunityMin.description ?? description)
    setEtapes(opportunityMin.description ?? etapes)

    if(opportunityMin?.amount) handleOnChangeAmount(opportunityMin.amount.toString())

    initializeSelectedParcelle(opportunityMin)
    initializeDefaultStep(opportunityMin)

  }

  useEffect(() => {
    if(opportunity) {
      initializeDefaultDataValues(opportunity)
    }


  }, [opportunity])

  const getValidateButton = () => {
    return (isUpdate) ? "Modifier" : "Ajouter";
  }


  const parcelleOptions =
    contact.lands.map((parcelle) => {
      const parcelleArea = parcelle.resume.area ?? ""
      const cityName = parcelle.resume?.city_name ?? ""
      const regionName = parcelle.resume?.region_name ?? ""

      return {
        label: parcelle.resume.code_number ? parcelle.resume.code_number.toString() + ` (${parcelleArea}m² - ${cityName} - ${regionName})` : "",
        value: parcelle._id.toString(),
      }
    });

  const defaultSteps = [
    {
      label: "En cours",
      value: "En cours",
    },
    {
      label: "Promesse de vente",
      value: "Promesse de vente",
    },
    {
      label: "Vendue",
      value: "Vendue",
    },
    {
      label: "Perdue",
      value: "Perdue",
    }
  ];

  const affaireSchema = z.object({
    name: z.string().max(50, {message: "50 caractères max"}).min(1, {message: "Champ obligatoire"}),
    land_id: z.string({message: "Champ obligatoire"}).min(1, {message : "Champ obligatoire"}),
    contact_id: z.string({message: "Champ obligatoire"}).min(1, {message : "Champ obligatoire"}),
    amount: z.string().regex(/^$|^(\s*|\d+(\.\d{1,2})?)$/, {message: "montant invalide"}),
    description: z.string().max(200, {message: "200 caractères max"}).optional(),
    status: z.string({message: "Champ obligatoire"}).max(50, {message: "100 caractères max"}).min(1)
  
  })

  const buildAffaireRequest = () => {
    return {
      _id: id,
      name: nomAffaire,
      land_id: selectedParcelle.value,
      contact_id: contact._id,
      amount: montant ? montant.replace(/\D/g, '') : '',
      description: description,
      status: etapes,
      documents: []
    };
  }

  const verifyAffaireData = (affaireData) => {
    try {
      console.log("affaireData", affaireData)
      affaireSchema.parse(affaireData);
    } catch (error) {
      if (error instanceof z.ZodError) {
        const validationErrors = {};
        console.log('in zod verif error.errors', error.errors)
        error.errors.forEach((err) => {
          const fieldName = err.path[0];
          validationErrors[fieldName] = err.message;
        });
        setErrors(validationErrors);
        throw error
      }
      else{
        throw error
      }
    }

  }

  const ajouterNouvelleAffaire = async () => {
    try{
      const affaireRequest = buildAffaireRequest()
      verifyAffaireData(affaireRequest)
      await ajouterAffaire(affaireRequest)
      successtoast();
      closeIt();

    }catch(error){
      if(error instanceof z.ZodError){
        toaster.notifyError('verifier les champs')

      }else{
        toaster.notifyError('probleme interne : ' + error.message);

      }
    }

    
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: {
      xl:"translate(-50%, -50%)",
      lg:"translate(-50%, -50%)",
      md:"translate(-50%, -50%)",
      sm:"translate(-50%, -50%)",
      xs:"translate(-41%, -50%)",
    },
    width: {
      xl:500,
      lg:500,
      md:500,
      sm:500,
      xs:300
    },
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const handleOnChangeAmount = (value) => {
    setFormatedAmount(formatCurrency(value))
    setMontant(value)
  } 

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <ToastContainer />
      <Box sx={style}>
        <h4 style={{ marginBottom: 20 }}>Ajouter une affaire</h4>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { my: 1, maxWidth: "436px" },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            required
            id="outlined-required"
            label="Nom de l'affaire"
            fullWidth
            value={nomAffaire}
            onChange={(e) => setNomAffaire(e.target.value)}
          />
            {errors.name ? <p style={{color: 'red'}}>{errors.name}</p> : <p></p>}
          {(isUpdate) ? "" :
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Parcelle n°"
                  value={selectedParcelle.label} />
              )}
              fullWidth
              options={parcelleOptions}
              onChange={(event, newValue) => {
                setSelectedParcelle(newValue ?? {label: '', id: null});
              }}
              isOptionEqualToValue={(option, value) => option.value === value}
            />
          }
              {errors.land_id ? <p style={{color: 'red'}}>{errors.land_id}</p> : <p></p>}
          <TextField
            id="outlined-required"
            label="Montant"
            fullWidth
            value={formatedAmount}
            onChange={(e) => handleOnChangeAmount(e.target.value)}
          />
            {errors.amount ? <p style={{color: 'red'}}>{errors.amount}</p> : <p></p>}
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField {...params} label="Etape de l'affaire" />
            )}
            fullWidth
            options={defaultSteps}
            value={etapes}
            onChange={(event, newValue) => {
              setEtapes(
                newValue ? newValue.value : null
              );
            }}
            isOptionEqualToValue={(option, value) => option.value === value}
          />
            {errors.status ? <p style={{color: 'red'}}>{errors.status}</p> : <p></p>}
            <div width={'100%'}>
              <label htmlFor={'textareaId'} 
                style={{width: "100%"}}>
              Description 
              </label>
              <textarea
                required
                id="textareaId"
                label="Description"
                style={{width: "100%"}}
                rows={5}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                size="medium"
              />
                {errors.description ? <p style={{color: 'red'}}>{errors.description}</p> : <p></p>}
            </div>
          {/* <Box sx={{ mt: 2 }}>
            <InputFile handleUpload={handleUpload} />
          </Box> */}
          <Box sx={{ display: "flex", justifyContent: "flex-end", pt: 2 }}>
            <Button
              sx={{
                background: "#757575",
                color: "#fff",
                "&:hover": { background: "#757575" },
                mr: 2,
              }}
              onClick={closeIt}
            >
              Annuler
            </Button>
            <Button
              sx={{
                background: "#299CDB",
                color: "#fff",
                "&:hover": { background: "#299CDB" },
              }}
              onClick={ajouterNouvelleAffaire}
            >
              {getValidateButton()}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

CreateAffaire.propTypes = {
  closeIt: PropTypes.func.isRequired,
  opportunity: PropTypes.any,
}

export default CreateAffaire;
