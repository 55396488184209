import {createContext, useContext, useMemo, useState} from "react";

const TransactionsAndFaosContext = createContext()

export const useTransactionsAndFaos = () => useContext(TransactionsAndFaosContext)

export const TransactionsAndFaosProvider = ({children}) => {
    const [transactions, setTransactions] = useState([])
    const [faos, setFaos] = useState([])

    const contextValue = useMemo(() => ({
        transactions,
        setTransactions,
        faos,
        setFaos
    }), [transactions, setTransactions, faos, setFaos])

    return (
        <TransactionsAndFaosContext.Provider value={contextValue}>
            {children}
        </TransactionsAndFaosContext.Provider>
    )
}