import React, { useEffect, useMemo, useRef, useState } from "react";
import { useApplication } from "../../hooks/UseApplication";

// import Table from '@mui/material/Table';
import {
  Box,
  Typography,
  Chip,
  Badge,
  CircularProgress,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
} from "@mui/material";

/**** Svgs */
import Gestion from "../../assets/Images/icons/gestion.svg";

/*****Ui icons */
import StarBorderIcon from "@mui/icons-material/StarBorder";
import zipChIcon from "../../assets/Images/logo/zip_logo_whtie_squared.jpg";
import linkedinIcon from "../../assets/Images/logo/linkedin.png";

/*****import some utilities components */
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";

/****import styles */
import { detailparcelleStyles } from "./components/styles";

/*****import components */
import DetailMap from "./components/detailmaps";
import EnqueteDetail from "./components/enquete";
import TransactionDetail from "./components/transaction";
import DetailInfos from "./components/infos";
import { useToast } from "../../Context/ToastContext";
import jwtInterceptor from "../../service/jwtInterceptor";
import { LAND_ENDPOINTS } from "../../utils/api";
import { landService, LandService } from "../../service/LandService";
import Modal from "@mui/material/Modal";
import CreateNote from "../detailcrm/modals/creationote";
import CreateNoteDynamic from "../CreateNoteDynamic";
import CustomBtn from "../customBtns/CustomBtn";
import { noteService } from "../../service/NoteService";
import Note from "../detailcrm/note/note";
import TransactionsBadge from "./components/TransactionsBadge";
import { TransactionsAndFaosProvider } from "../../Context/crm/TransactionsAndFaosContext";
import EnquetesBadge from "./components/EnquetesBadge";
import { Form } from "react-bootstrap";
import { ChangeFavoriteDialog } from "../FavoriteBox";
import StarOutlined from "@mui/icons-material/StarOutlined";
import { landService as crmLandService } from "./../../service/crm/LandService";
import CreateContact from "../detailcrm/modals/creationcontact";
import { contactService } from "../../service/crm/ContactService";
import {Helmet} from "react-helmet";
import NoteDynamic from "../NoteDynamic";

const DetailParcelle = ({
  detailMapData,
  detailTransaction,
  detailEnquete,
}) => {
  const [detailParcelle, setDetailParcelle] = useState({});
  const { id } = useParams();
  const { details, setDetails } = useApplication();
  const innerHeadRef = useRef(null);
  // const location = useLocation()
  const [isManageLoading, setIsManageLoading] = useState(false);
  const navigate = useNavigate();
  const [openNote, setOpenNote] = useState(false);
  const [openCreateContact, setOpenCreateContact] = useState(false);
  const [notes, setNotes] = useState([]);
  const [changeOnChild, setChangeOnChild] = useState(false);
  const [openFavoriteDialog, setOpenFavoriteDialog] = useState(false);
  const [crmLandId, setCrmLandId] = useState(0);
  const [contactsList, setContactsList] = useState([]);

  const toaster = useToast();

  async function addElasticContactToContactList(rowWithoutSource) {
    let newContactsList = [];
    console.log("inside addElastic");

    if (rowWithoutSource.owners && rowWithoutSource.owners.length > 0) {
      newContactsList = rowWithoutSource.owners.map((owner) => {
        console.log("nom >>", owner);
        return {
          name: owner.name,
          lastName: owner.lastName,
          type: owner.type === "private" ? "privé" : "public",
          byCRM: false,
          link: "#",
        };
      });
    }

    return newContactsList;
  }

  async function fetchDetails() {
    const detailById = await landService
      .findLandById(id)
      .catch((error) => toaster.notifyError(error.message));
    if (!detailById) {
      toaster.notifyError("impossible de trouver la parcelle");
    } else {
      const newElasticContact = await addElasticContactToContactList(
        detailById._source
      );
      const newCRMContact = await fetchContacts(detailById._source.id);
      const newCOntactList = newElasticContact.concat(newCRMContact);
      console.log("new contact list ", newCOntactList);
      setContactsList(newCOntactList);
    }
    console.log("detailById inside fetchDetails", detailById);

    setDetails(detailById._source);
    setDetailParcelle(detailById._source);
    fetchNotes(detailById._source.land_id);

    crmLandService.isManaged(detailById._source.land_id).then((response) => {
      setCrmLandId(response);
    });
  }

  async function fetchNotes(landId) {
    const notesData = await noteService.findNoteByLandId(landId);
    setNotes(notesData);
  }

  useEffect(() => {
    console.log("inside useEffect detailParcelleComponent");

    fetchDetails();
    console.log("inside useEffect of DetailsParcelleComponent");
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const grandParentWidth = entry.target.clientWidth;
        if (innerHeadRef.current) {
          innerHeadRef.current.style.width = grandParentWidth + "px";
        }
      }
    });

    resizeObserver.observe(innerHeadRef.current.parentElement);
    return () => {
      resizeObserver.disconnect();
    };
  }, [id, changeOnChild]);

  function scrollToElement(targetId) {
    const target = document.querySelector(targetId);
    if (target) {
      window.scrollTo({
        top: target.offsetTop,
        behavior: "smooth", // Défilement en douceur
      });
    }
  }

  const handleCloseFavoriteModal = () => {
    fetchDetails();
    setOpenFavoriteDialog(false);
  };

  const handleOpenNote = () => setOpenNote(true);
  const handleCloseNote = () => {
    setOpenNote(false);
    setChangeOnChild(!changeOnChild);
  };

  const handleCloseCreateContact = () => {
    setOpenCreateContact(false);
  };
  const handleOpenCreateContact = () => {
    setOpenCreateContact(true);
  };

  const manageLand = async () => {
    setIsManageLoading(true);
    const manageErrorMessage = "Erreur durant la gestion de la parcelle : :errorMessage";
    try {
      const response = await jwtInterceptor.post(LAND_ENDPOINTS.manage.replace(":landId", detailParcelle.id));
      if (response.status.toString().startsWith("2")) {
        toaster.notifySuccess("Succès : Vous pouvez la consulter dans le CRM.");
        setCrmLandId(1); // Mettre à jour l'état pour changer le bouton
      } else {
        toaster.notifyError(manageErrorMessage.replace(":errorMessage", response.data.message));
      }
      fetchDetails()
    } catch (error) {
      toaster.notifyError(manageErrorMessage.replace(":errorMessage", error.message));
    } finally {
      setIsManageLoading(false);
    }
  };

  const getStarIcon = () => {
    return details.landsInteractions?.isFavorites ? (<StarOutlined sx={{ fill: "#363636", height: "17px" }} />) :
        (
          <StarBorderIcon sx={{ fill: "#fff", height: "17px" }} />
        );
  };

  const formatSearchUrl = (baseUrl, query) => {
    return `${baseUrl}${encodeURIComponent(query)}`;
  };

  /**** styles constantes */
  const responsiveFloatingParcels = {
    top: { xl: "0", lg: "0", md: "0", sm: "0", xs: "0" },
  };

  const paddingInlineNone = {
    paddingInline: "0px",
  };

  const contactTdStyle = {
    maxWidth: "235px",
  };

  const controllWidth = {
    width: {
      xl: '446px',
      lg: '100%',
      md: '100%',
      sm: '671px',
      xs: '671px'
    }
  };

  const fetchContacts = async (externalLandId) => {
    const contacts = await contactService.findByExternalLandId(externalLandId);
    let newContactsList = [];

    newContactsList = contacts.map((contact) => {
      console.log("nom >> contact>W>", contact);
      return {
        name: contact.lastName + " " + contact.firstName,
        type: contact.types,
        byCRM: true,
        link: "/detail/:id/:contactId"
          .replace(":id", contact.lands[0]._id)
          .replace(":contactId", contact._id),
      };
    });

    return newContactsList;
  }; 

  const handleContactCreated = async (newContact) => {
    // Mettre à jour la liste des contacts ici
    await fetchContacts(detailParcelle.id);
    setChangeOnChild(!changeOnChild); // Déclencher une re-rendu si nécessaire
  };

  const renderManageButtonJsx = () => {
    if(crmLandId === 0 ) {
      return (
          <Box sx={{ marginInline: { xl: 0, lg: 0, md: 0, sm: 0, xs: 1 }, marginBlock: 1 }}>
            <CustomBtn
                bgColor={"#3F50B6"}
                label="Gérer"
                iconBg={"#3F50B6"}
                icon={"+"}
                mR={"15px"}
                click={manageLand}
            />
          </Box>
      )
    }else {
      return (
          <Box sx={{ marginInline: { xl: 0, lg: 0, md: 0, sm: 0, xs: 1 }, marginBlock: 1 }}>
            <CustomBtn
                bgColor={"#3F50B6"}
                label="Contact"
                iconBg={"#3F50B6"}
                icon={"+"}
                mR={"15px"}
                click={handleOpenCreateContact}
            />
          </Box>
      )

    }
  }

  return (
    <>
      <Helmet>
        <title>{`${detailParcelle.code_number}, ${detailParcelle.city_name} - Mixdata`}</title>
      </Helmet>
      <TransactionsAndFaosProvider>
        <Box className="Layout bg-white mt-4 rounded  mx-4">
          <Box className="container-fluid">
            <Box className="row">
              <Box
                className="col-12 d-flex justify-content-between align-items-center innerHead"
                sx={
                  detailParcelle.address_full
                    ? {
                        background: "#fff",
                        position: "fixed",
                        paddingInline: "15px",
                        zIndex: 9999,
                        filter:
                          "drop-shadow(0px 4px 4px rgba(230, 230, 230, 0.25))",
                        flexWrap: "wrap",
                        ...responsiveFloatingParcels,
                      }
                    : {
                        paddingBlock: "10px",
                        background: "#fff",
                        position: "fixed",
                        paddingInline: "15px",
                        zIndex: 9999,
                        filter:
                          "drop-shadow(0px 4px 4px rgba(230, 230, 230, 0.25))",
                        flexWrap: "wrap",
                        ...responsiveFloatingParcels,
                      }
                }
                ref={innerHeadRef}
              >
                <Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="h4"
                      className=""
                      sx={{
                        ...detailparcelleStyles.fontWeightTitle,
                        mr: 1,
                        fontSize: "1.625rem",
                      }}
                    >
                      Parcelle n°{detailParcelle.code_number}
                    </Typography>
                  </Box>
                  <Box
                    sx={{ marginBottom: { xl: 0, lg: 0, md: 0, sm: 2, xs: 2 } }}
                    className="d-flex align-items-center"
                  >
                    <p class="mb-0 me-2">{detailParcelle.address_full}</p>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: {
                      xl: "space-between",
                      lg: "space-between",
                      md: "space-between",
                      sm: "space-between",
                      xs: "center",
                    },
                    minWidth: {
                      xl: "400px",
                      lg: "400px",
                      md: "400px",
                      sm: "400px",
                      xs: "100%",
                    },
                    flexWrap: "wrap",
                  }}
                >
                  <Box>
                    <CustomBtn
                      bgColor={
                        details.landsInteractions?.isFavorites
                          ? "#FFC107"
                          : "#363636"
                      }
                      label="Favoris"
                      iconBg={
                        details.landsInteractions?.isFavorites
                          ? "#FFC107"
                          : "#363636"
                      }
                      icon={getStarIcon()}
                      mR={"15px"}
                      styles={"7px"}
                      textColor={
                        details.landsInteractions?.isFavorites
                          ? "#363636"
                          : "#fff"
                      }
                      click={() => setOpenFavoriteDialog(true)}
                    />
                  </Box>
                  <Box>
                    <EnquetesBadge
                      onClick={() => scrollToElement("#transaction")}
                    />
                  </Box>
                  <Box sx={{mt: {xl:0 , lg: 0, md: 0, sm:0 ,xs:1}}}>
                    <TransactionsBadge
                      onClick={() => scrollToElement("#enquete")}
                    />
                  </Box>
                  <Box
                    sx={{
                      marginInline: { xl: 0, lg: 0, md: 0, sm: 0, xs: 1 },
                      marginBlock: 1,
                    }}
                  >
                    <CustomBtn
                      bgColor={"rgb(41, 156, 219)"}
                      label="Note"
                      iconBg={"rgb(41, 156, 219)"}
                      icon={"+"}
                      mR={"15px"}
                      click={handleOpenNote}
                    />
                  </Box>
                  <Box>
                    {isManageLoading ? (<CircularProgress color="secondary" />) : renderManageButtonJsx()}
                  </Box>
                </Box>
              </Box>
              {detailParcelle && <DetailMap detailMapData={detailParcelle} />}
            </Box>
          </Box>
        </Box>
        <Box
        className="px-4"
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              width: {
                xl: "68%",
                lg: "100%",
                md: "100%",
                sm: "100%",
                xs: "100%",
              },
            }}
          >
            <DetailInfos />
            <NoteDynamic notes={notes} onUpdate={() => fetchDetails()} addPaperStyle={"p-4"} createNote={handleOpenNote} />
            <EnqueteDetail details={detailParcelle} />
            <TransactionDetail detailTransaction={detailParcelle} />
          </Box>
          <Box
            sx={{
              width: {
                xl: "30%",
                lg: "100%",
                md: "100%",
                sm: "100%",
                xs: "100%",
              },
              backgroundColor: "#fff",
              borderRadius: "8px",
              padding: 3,
              margin: 3,
              ml: { lg: 0, md: 0, sm: 0, xs: 0 },
              mr: 0,
              mt: {
                xl: 3,
                lg: 0,
                md: 0,
                sm: 0,
                xs:0
              }
            }}
          >
            <Box className="d-flex justify-content-between align-items-center">
              <Typography
                variant="h5"
                component="h5"
                style={detailparcelleStyles.fontWeightTitle}
              >
                Contacts
              </Typography>
            </Box>

            <Box className="overflow-auto" sx={{ mb: 3 }}>
              <Table size="small" aria-label="purchases" sx={{...controllWidth}}>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ ...contactTdStyle, ...paddingInlineNone }}>
                      Nom
                    </TableCell>
                    <TableCell sx={paddingInlineNone}>Type</TableCell>
                    <TableCell sx={paddingInlineNone}>Rechercher</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ height: "inherit" }}>
                  {contactsList && contactsList.length > 0 ? (
                    contactsList.map((item) => {
                      const query = item.name || item.crm;
                      console.log(item);
                      const searchChUrl = formatSearchUrl(
                        "https://search.ch/?q=",
                        query
                      );
                      const zipChUrl = formatSearchUrl(
                        "https://zip.ch/fr/results/?q=",
                        query
                      );
                      const linkedInUrl = formatSearchUrl(
                        "https://www.linkedin.com/search/results/all/?keywords=",
                        query
                      );

                      return (
                        <TableRow
                          key={item.name}
                          onClick={() => navigate(item.link)}
                          sx={
                            item.byCRM
                              ? { cursor: "pointer" }
                              : { cursor: "inherit" }
                          }
                        >
                          <TableCell component="th" scope="row">
                            {item.name}{" "}
                            
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            sx={paddingInlineNone}
                          >
                            {item.type}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            sx={paddingInlineNone}
                          >
                            {item.byCRM ? (
                              <>
                              <Chip sx={{ height: "20px" }} label="CRM" />
                              </>
                            ) : (
                              <>
                                <a
                                  href={searchChUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    height="20"
                                    alt="search.ch"
                                    src="https://lib.search.ch/favicon.ico?c=3"
                                  />
                                </a>
                                <a
                                  href={zipChUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ marginInline: "5px" }}
                                >
                                  <img
                                    height="20"
                                    alt="zip.ch"
                                    src={zipChIcon}
                                    style={{ objectFit: "contain" }}
                                  />
                                </a>
                                <a
                                  href={linkedInUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    height="21"
                                    alt="linkedin"
                                    src={linkedinIcon}
                                    style={{ objectFit: "contain" }}
                                  />
                                </a>
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4}>Aucun contact trouvé</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </Box>
        <Modal
          open={openNote}
          onClose={handleCloseNote}
          aria-labelledby="creation-note-title"
          aria-describedby="creation-note-description"
        >
          <CreateNoteDynamic
            closeIt={handleCloseNote}
            ownerId={detailParcelle.land_id}
            ownerType={"land"}
          />
        </Modal>
        <Modal
          open={openCreateContact}
          onClose={handleCloseCreateContact}
          aria-labelledby="creation-note-title"
          aria-describedby="creation-note-description"
          style={{ zIndex: "99999" }}
        >
          <CreateContact
            closeIt={handleCloseCreateContact}
            numParcelle={crmLandId}
            onContactCreated={handleContactCreated}
          />
        </Modal>
      </TransactionsAndFaosProvider>
      <ChangeFavoriteDialog
        row={detailParcelle}
        open={openFavoriteDialog}
        onClose={handleCloseFavoriteModal}
      />
    </>
  );
};

export default DetailParcelle;
