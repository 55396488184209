import React from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import { Routing } from "./routers/index";
import { AuthContextProvider } from "./Context/Context";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastProvider } from "./Context/ToastContext";
import { FavoriteProvider } from "./Context/FavoriteContext";

function App() {
  return (
    <BrowserRouter>
      <ToastProvider>
        <AuthContextProvider>
          <FavoriteProvider>
            <div className="d-flex">
              <Routing />
            </div>
          </FavoriteProvider>
        </AuthContextProvider>
      </ToastProvider>
    </BrowserRouter>
  );
}

export default App;
