import {Box, Button, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import React, {useState} from "react";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateNoteDynamic from "../CreateNoteDynamic";
import Modal from "@mui/material/Modal";
import {AppCustomConfirmationDialog} from "../AppCustomConfirmationDialog";
import {noteService} from "../../service/NoteService";
import {useToast} from "../../Context/ToastContext";

export const NotesResumeParcelle = ({notes, onNotesChanges}) => {

    const toaster = useToast()
    const [openUpdateNote, setOpenUpdateNote] = useState(false)
    const [selectedNote, setSelectedNote] = useState(null)
    const [openDeleteNote, setOpenDeleteNote] = useState(false)

    const paddingInlineNone = {
        paddingInline: "0px",
    };

    const tdStyle = {
        maxWidth: "200px",
        paddingRight: "10px!important",
    };

    const formatDate = (dateString) => {
        const options = { year: "numeric", month: "numeric", day: "numeric" };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const handleOpenUpdateNote = (note) => {
        setSelectedNote(note)
        setOpenUpdateNote(true)
    }

    const handleOpenDeleteNote = (note) => {
        setSelectedNote(note)
        setOpenDeleteNote(true)
    }

    const handleDeleteNote = async () => {
        try {
            if(!selectedNote) throw new Error('aucune note selectionné')

            const isDeleted = await noteService.deleteNote(selectedNote._id).catch((error) => {
                throw error
            })

            if(!isDeleted) throw new Error('impossible de supprimer la note')

            setSelectedNote(undefined)
            onNotesChanges()

        }catch (error) {
            console.error(error)
            toaster.notifyError(error.message)
        }
    }

    const onCloseUpdateNote = () => {
        setOpenUpdateNote(false)
        onNotesChanges()
    }

    return (
        <>
        <Table size="small" aria-label="purchases">
            <TableHead>
                <TableRow>
                    <TableCell sx={{ ...tdStyle, ...paddingInlineNone }}>
                        Date
                    </TableCell>
                    <TableCell sx={paddingInlineNone}>Description</TableCell>
                    <TableCell sx={paddingInlineNone}>Actions</TableCell>
                </TableRow>
            </TableHead>
            <TableBody style={{ overflow: "auto" }}>
                {notes && notes.length > 0 ? (
                    notes.map((item) => {

                        return (
                            <TableRow
                                key={item.createdAt}
                            >
                                <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{ ...tdStyle, ...paddingInlineNone }}
                                >
                                    {formatDate(item.createdAt)}
                                </TableCell>
                                <TableCell
                                    component="th"
                                    scope="row"
                                    sx={paddingInlineNone}
                                >
                                    {item.description}
                                </TableCell>
                                <TableCell
                                    component="th"
                                    scope="row"
                                    sx={paddingInlineNone}
                                >
                                        {/* <ModeEditIcon sx={{ mr: 2 }} />*/}
                                        <Button
                                            onClick={() => handleOpenUpdateNote(item)}
                                        >
                                            <ModeEditIcon sx={{ color: "blue" }} />
                                        </Button>
                                        {/* <ModeEditIcon sx={{ mr: 2 }} />*/}
                                        <Button
                                            onClick={() => handleOpenDeleteNote(item)}
                                        >
                                            <DeleteIcon sx={{ color: "red" }} />
                                        </Button>
                                </TableCell>
                            </TableRow>
                        );
                    })
                ) : (
                    <TableRow>
                        <TableCell colSpan={4}>Aucune note trouvé</TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
            <Modal
                open={openUpdateNote}
                onClose={onCloseUpdateNote}
                aria-labelledby="modification-note"
                aria-describedby="modification-note"
            >

                {selectedNote ?
                    (
                        <CreateNoteDynamic note={selectedNote} closeIt={onCloseUpdateNote} ownerId={selectedNote.landId} ownerType={'land'} >
                        </CreateNoteDynamic>
                    )
                    : (<></>)
                }

            </Modal>
            <AppCustomConfirmationDialog
                open={openDeleteNote}
                onAccept={handleDeleteNote}
                onClose={() => setOpenDeleteNote(false)}
                onDecline={() => setOpenDeleteNote(false)}
                bodyMessage={'Souhaitez-vous supprimer définitivement la note?'}
                title={'Suppression note'}
            />
    </>
    )
}