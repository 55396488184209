import React, { useEffect, useState } from "react";
import CreateTask from "../modals/creationtache";
import Modal from "@mui/material/Modal";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Select,
  MenuItem,
  Button,
  CircularProgress
} from "@mui/material";
import { supprimerAffaire } from "../../../indexedDb";
import Etat from "../../etatComponent/etatComponent.";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer } from "react-toastify";
import { useContact } from "../../../Context/crm/ContactContext";
import Creationaffaire from "../modals/creationaffaire";
import CustomBtn from "../../customBtns/CustomBtn";
import { formatDate } from "../../../utils/helper";
import { AppCustomConfirmationDialog } from "../../AppCustomConfirmationDialog";
import { taskService } from "../../../service/crm/TacheService";
import { useToast } from "../../../Context/ToastContext";
import PropTypes from "prop-types";

const Affaires = ({ createAffaire }) => {
  const { contact, fetchContactData, refreshContactData } = useContact();
  const [selectedTask, setSelectedTask] = useState(null);
  const [affaireAssociee, setAffaireAssociee] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [openUpdateOpportunity, setOpenUpdateOpportunity] = useState(false);
  const [openDeleteTaskDialog, setOpenDeleteTaskDialog] = useState(false);
  const toaster = useToast();
  const [openUpdateTask, setOpenUpdateTask] = React.useState(false);
  const [openDeleteOpportunityDialog, setOpenDeleteOpportunityDialog] = useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setLoading(true);
    setOpen(false);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const handleCloseUpdateTask = () => {
    setOpenUpdateTask(false);
  };

  const handleOpenUpdateTask = (tache, affaire) => {
    setSelectedTask(tache);
    setSelectedAffaire(affaire);
    setOpenUpdateTask(true);
  };
  const [selectedAffaire, setSelectedAffaire] = useState(null);

  const formatNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  useEffect(() => {
    setAffaireAssociee(contact.opportunities);

    setLoading(false);
  }, [contact]);

  const handleChange = (event, index, id) => {
    const selectedValue = event.target.value;
    setSelectedAffaire(affaireAssociee[index]);

    if (selectedValue === "modification") {
      handleOpenUpdateOpportunity();

    } else if (selectedValue === "suppression") {
      onOpenDeleteOpportunityDialog();

    } else if (selectedValue === "add_task") {
      handleOpen();
    }
  };

  const handleOpenUpdateOpportunity = () => {
    setOpenUpdateOpportunity(true);
  };

  const handleCloseUpdateOpportunity = () => {
    fetchContactData(contact._id);
    setOpenUpdateOpportunity(false);
  };

  console.log("aaffaires>>>", affaireAssociee);

  const BbottomNone = {
    borderBottom: "none",
  };

  const onOpenDeleteTask = (task) => {
    setSelectedTask(task);
    setOpenDeleteTaskDialog(true);
  };

  const onDeleteTask = async () => {
    if (selectedTask) {
      try {
        const isDeleted = await taskService.deleteTask(selectedTask._id);
        if (!isDeleted) {
          toaster.notifyError("impossible de supprimer la tache");
        } else {
          refreshContactData();
          toaster.notifySuccess("tache supprimé avec succès");
        }
      } catch (error) {
        toaster.notifyError(error.message);
      }
    } else {
      toaster.notifyError("aucune tache selectionné");
    }
  };

  const onOpenDeleteOpportunityDialog = () => {
    setOpenDeleteOpportunityDialog(true);
  };

  const onDeleteOpportunity = async () => {
    if (selectedAffaire) {
      try {
        const isDeleted = await supprimerAffaire(selectedAffaire._id);
        if (!isDeleted) {
          toaster.notifyError("impossible de supprimer l'affaire");
        } else {
          refreshContactData();
          toaster.notifySuccess("affaire supprimé avec succès");
        }
      } catch (error) {
        toaster.notifyError(error.message);
      }
    } else {
      toaster.notifyError("aucune tache selectionné");
    }
  };

      //styles

      const w100mobile = { 
        width :
          {
            xl: "33.3%!important",
            lg: "33.3%!important",
            md: "50%!important",
            sm: "100%!important",
            xs: "100%!important"
        }
      }

  return loading ? (
    <CircularProgress />
  ) : (
    <Box>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: " space-between",
          alignItems: "flex-start", mb: "20px"
        }}
      >
        <h3 className="mb-0">Suivi des affaires</h3>
        <CustomBtn
          bgColor={"#299CDB"}
          label="Affaire"
          iconBg={"#3ba9e5"}
          icon={"+"}
          click={createAffaire}
        />
      </Box>
      <Box sx={{ overflowX: "auto" }}>
        <Table className="affaire_table">
          <TableHead sx={{ background: "rgba(41, 156, 219, 0.05)" }}>
            <TableCell>Nom de l'affaire</TableCell>
            <TableCell>N° parcelle</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Etapes</TableCell>
            <TableCell>Montant</TableCell>
            <TableCell>Date de création</TableCell>
            <TableCell>Actions</TableCell>
          </TableHead>
          {affaireAssociee.length === 0 ? (
            <TableRow>
              <TableCell colSpan={7} align="left">
                Pas d'affaire disponible
              </TableCell>
            </TableRow>
          ) : (
            affaireAssociee.map((affaire, index) => {
              const tachesFiltrees = affaire.tasks;
              return (
                <TableBody
                  key={index}
                  sx={{
                    height: "auto",
                    mb: 5,
                    background: "#FAFAFA",
                    borderBottom: "25px #fff solid",
                    '&:last-child': {
                      borderBottom: "0px",
                    },
                  }}
                >
                  <TableRow sx={{ background: "#FAFAFA" }}>
                    <TableCell sx={BbottomNone}>{affaire.name}</TableCell>
                    <TableCell sx={BbottomNone}>
                      {affaire.land?.resume
                        ? affaire.land.resume.code_number
                        : ""}
                    </TableCell>
                    <TableCell sx={BbottomNone}>
                      {affaire.description}
                    </TableCell>
                    <TableCell sx={BbottomNone}>{affaire.status}</TableCell>
                    <TableCell sx={BbottomNone}>
                      {affaire.amount
                        ? formatNumber(affaire.amount)
                        : "Indisponible"}
                    </TableCell>
                    <TableCell sx={BbottomNone}>
                      {formatDate(affaire.createdAt)}
                    </TableCell>
                    <TableCell sx={BbottomNone}>
                      <Select
                        labelId={"actions"}
                        id={"actions"}
                        label="Selectionnez une option"
                        value={affaire.valeurSelectionnee || "default"}
                        onChange={(event) =>
                          handleChange(event, index, affaire._id)
                        }
                      >
                        <MenuItem value={"default"}>
                          Sélectionner
                        </MenuItem>
                        <MenuItem value={"modification"}>Modifier</MenuItem>
                        <MenuItem value={"suppression"}>Supprimer</MenuItem>
                        <MenuItem value={"add_task"}>
                          Ajouter une tâche
                        </MenuItem>
                      </Select>
                    </TableCell>
                  </TableRow>
                  <TableRow className="rowTask" sx={{ borderBottom: "none" }}>
                    <TableCell
                      className={
                        index % 2 === 0
                          ? "TacheCell "
                          : "TacheCell TacheCellWithBackground"
                      }
                      sx={{ px: 3, pb: 5, borderBottom: "none" }}
                      colSpan={7}
                    >
                      <h5
                        style={{
                          color: "#299CDB",
                          fontWeight: "bold",
                          marginBottom: "15px",
                        }}
                      >
                        Tâches
                      </h5>
                      {tachesFiltrees && tachesFiltrees.length > 0 ? (
                        tachesFiltrees.map((tache, index) => (
                          <Accordion
                            sx={{ width: "100%!important" }}
                            key={`accordion-${tache.id}`}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id={index}
                              sx={{
                                width: "100%!important",
                                borderBottom: "1px solid #e7e7e7e8",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  width: "100%!important",
                                  ml: "15px!important",
                                }}
                              >
                                <Box
                                  sx={{
                                    width: "50%!important",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "#299CDB",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {tache.goal}
                                  </Typography>
                                  <Etat
                                    type={tache.status}
                                    mLeft={"15px!important"}
                                  />
                                </Box>
                                <Box
                                  sx={{
                                    width: "50%!important",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <Button
                                    onClick={() =>
                                      handleOpenUpdateTask(tache, affaire)
                                    }
                                  >
                                    <ModeEditIcon sx={{ mr: 2 }} />
                                  </Button>
                                  <Button
                                    onClick={() => onOpenDeleteTask(tache)}
                                  >
                                    <DeleteIcon sx={{ color: "red" }} />
                                  </Button>
                                </Box>
                              </Box>
                            </AccordionSummary>
                            <AccordionDetails
                              sx={{ width: "100%!important", padding: "25px" }}
                            >
                              <Box sx={{ display: "flex", flexWrap: 'wrap' }}>
                                <Box sx={w100mobile}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        maxWidth: "150px",
                                        width: "100%",
                                        fontWeight: "600",
                                      }}
                                    >
                                      Objectif
                                    </Typography>
                                    <Typography>{tache.goal}</Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        maxWidth: "150px",
                                        width: "100%",
                                        fontWeight: "600",
                                      }}
                                    >
                                      Description
                                    </Typography>
                                    <Typography>{tache.description}</Typography>
                                  </Box>
                                </Box>
                                <Box sx={w100mobile}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        maxWidth: "150px",
                                        width: "100%",
                                        fontWeight: "600",
                                      }}
                                    >
                                      Date de création
                                    </Typography>
                                    <Typography>
                                      {formatDate(tache.createdAt)}
                                    </Typography>
                                  </Box>
                                  <Box
                                    className="excludedStyles"
                                    sx={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        maxWidth: "150px",
                                        width: "100%",
                                        fontWeight: "600",
                                      }}
                                    >
                                      Statut
                                    </Typography>
                                    <Etat
                                      type={tache.status}
                                      wdt={"50%!important"}
                                    />
                                  </Box>
                                </Box>
                                <Box sx={w100mobile}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        maxWidth: "150px",
                                        width: "100%",
                                        fontWeight: "600",
                                      }}
                                    >
                                      Date d'échéance
                                    </Typography>
                                    <Typography>
                                      {" "}
                                      {formatDate(tache.dueDate)}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </AccordionDetails>
                          </Accordion>
                        ))
                      ) : (
                        <Typography sx={{ fontWeight: "bold", p: 2 }}>
                          Pas de tâche disponible
                        </Typography>
                      )}
                    </TableCell>
                  </TableRow>
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="create_task"
                    aria-describedby="task_description"
                  >
                    <CreateTask
                      closeIt={handleClose}
                      affaire={selectedAffaire}
                    />
                  </Modal>
                  <Modal
                    open={openUpdateTask}
                    onClose={handleCloseUpdateTask}
                    aria-labelledby="create_task"
                    aria-describedby="task_description"
                  >
                    <CreateTask
                      closeIt={handleCloseUpdateTask}
                      affaire={selectedAffaire}
                      task={selectedTask}
                    />
                  </Modal>
                  <Modal
                    open={openUpdateOpportunity}
                    onClose={handleCloseUpdateOpportunity}
                    aria-labelledby="create_task"
                    aria-describedby="task_description"
                  >
                    <Creationaffaire
                      closeIt={handleCloseUpdateOpportunity}
                      opportunity={selectedAffaire}
                    />
                  </Modal>
                </TableBody>
              );
            })
          )}
        </Table>
      </Box>
      <AppCustomConfirmationDialog
        open={openDeleteTaskDialog}
        onAccept={onDeleteTask}
        onClose={() => setOpenDeleteTaskDialog(false)}
        onDecline={() => setOpenDeleteTaskDialog(false)}
        title={"Suppression tache"}
        bodyMessage={"Voulez-vous vraiment supprimer cette tache"}
      />

      <AppCustomConfirmationDialog
        open={openDeleteOpportunityDialog}
        onAccept={onDeleteOpportunity}
        onClose={() => setOpenDeleteOpportunityDialog(false)}
        onDecline={() => setOpenDeleteOpportunityDialog(false)}
        title={"Suppression affaire"}
        bodyMessage={"Voulez-vous vraiment supprimer cette affaire"}
      />
    </Box>
  );
};

Affaires.propTypes = {
  createAffaire: PropTypes.func.isRequired,
}

export default Affaires;
