import React, { useState } from "react";
import {Box, Typography, Card, Button} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {AppCustomConfirmationDialog} from "../../AppCustomConfirmationDialog";
import {noteService} from "../../../service/NoteService";
import {useToast} from "../../../Context/ToastContext";
import CustomBtn from "../../customBtns/CustomBtn";
import Creationote from "../modals/creationote";
import Modal from "@mui/material/Modal";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import PropTypes from "prop-types";

const Note = ({ notes, onUpdate, addPaperStyle, createNote}) => {
  const noteData = notes
  const [openDeleteNote, setOpenDeleteNote] = useState(false)
  const [openUpdateNote, setOpenUpdateNote] = useState(false)
  const [selectedNote, setSelectedNote] = useState(null)
  const toaster = useToast()

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleOpenDeleteNote = (note) => {
    setSelectedNote(note)
    setOpenDeleteNote(true)
  }

  const handleOpenUpdateNote = (note) => {
    setSelectedNote(note)
    setOpenUpdateNote(true)
  }

  const handleDeleteNote = async () => {
    try {
      if(!selectedNote) throw new Error('auccune note selectionné')

      const isDeleted = await noteService.deleteNote(selectedNote._id).catch((error) => {
        throw error
      })

      if(!isDeleted) throw new Error('impossible de supprimer la note')

      setSelectedNote(undefined)
      toaster.notifySuccess("La note a été supprimé avec succès")
      onUpdate()

    }catch (error) {
      toaster.notifyError(error.message)
    }

  }

  const onCloseUpdateNote = () => {
    setSelectedNote(undefined)
    setOpenUpdateNote(false)
    onUpdate()
  }

  const wrapInMobile = {
        flexWrap : {
            xl: 'nowrap',
            lg: 'nowrap',
            md: 'nowrap',
            sm: 'nowrap',
            xs: 'wrap'
        }
    }

    const mobileNoMargin = {
        marginLeft : {
            xl: '30px',
            lg: '30px',
            md: '30px',
            sm: '30px',
            xs: '0'
        }
    }
    const btnsWidth = {
        width : {
            xl: 'auto',
            lg: 'auto',
            md: 'auto',
            sm: 'auto',
            xs: '100%'
        }

    }


  return (
      <Box className={addPaperStyle + " Layout bg-white rounded"}>
      {createNote &&
          <Box
              sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: " space-between",
                  alignItems: "flex-start", mb: "20px"
              }}
          >
              <h3 className="mb-0">Notes</h3>
              <CustomBtn
                  bgColor={"#959FDB"}
                  label="Note"
                  iconBg={"#a3abe0"}
                  icon={"+"}
                  click={createNote}
              />
          </Box>

          }

      {noteData && noteData.length > 0 ? (
        noteData.map((notes, index) => (
          <Card
            key={`${notes.createdAt}-${notes.description}`}
            sx={{
              background: index % 2 === 0 ? "#f4fafd" : "#ffffff",
            }}
            className="mb-2"
          >
            <Box className="noteCordion">
              <Box sx={{display: 'flex' , width: 'auto', ...wrapInMobile}}>
                <Box>{formatDate(notes.createdAt)}</Box>
                <Box sx={{maxWidth:"891px", ...mobileNoMargin, wordWrap: 'anywhere'}}>{notes.description}</Box>
              </Box>
              <Box sx={{display: 'flex', ...btnsWidth, justifyContent: 'flex-end'}}>
              <Box>
                {/* <ModeEditIcon sx={{ mr: 2 }} />*/}
                <Button
                    onClick={() => handleOpenUpdateNote(notes)}
                >
                  <ModeEditIcon sx={{ color: "blue" }} />
                </Button>
              </Box>
              <Box>
              {/* <ModeEditIcon sx={{ mr: 2 }} />*/}
                <Button
                  onClick={() => handleOpenDeleteNote(notes)}
                >
                  <DeleteIcon sx={{ color: "red" }} />
                </Button>
              </Box>
              </Box>
            </Box>
            <AppCustomConfirmationDialog
              open={openDeleteNote}
              onAccept={handleDeleteNote}
              onClose={() => setOpenDeleteNote(false)}
              onDecline={() => setOpenDeleteNote(false)}
              bodyMessage={'Souhaitez-vous supprimer définitivement la note?'}
              title={'Suppression note'}
            />
          </Card>
        ))
      ) : (
        <Typography sx={{mb:1, fontSize:"0.875rem"}}>
          Pas de note disponible
        </Typography>
      )}

      <Modal
          open={openUpdateNote}
          onClose={onCloseUpdateNote}
          aria-labelledby="modification-note"
          aria-describedby="modification-note"
      >
        <Creationote note={selectedNote} closeIt={onCloseUpdateNote}>

        </Creationote>
      </Modal>
    </Box>
  );
};

Note.propTypes = {
  notes: PropTypes.array,
  onUpdate: PropTypes.func,
  addPaperStyle: PropTypes.any,
  createNote: PropTypes.func
}

export default Note;
