import React, {useEffect, useState} from "react";
import { Box, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import { ToastContainer, toast } from "react-toastify";
import { useToast } from "../Context/ToastContext";
import { ajouterNote } from "../indexedDb";
import note from "./detailcrm/note/note";

const CreateNoteDynamic = ({ closeIt, ownerId, ownerType, note }) => {
  const [description, setDescription] = useState("");
  const [id, setId] = useState(undefined)
  const toaster = useToast()

  //Toasts
  const champsvide = () =>
    toast.warn("Veuillez compléter les champs obligatoires");
  const successtoast = () => toaster.notifySuccess(id ? "Note modifiée avec succès" : "Note créé avec succès");



  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: {
      xl:"translate(-50%, -50%)",
      lg:"translate(-50%, -50%)",
      md:"translate(-50%, -50%)",
      sm:"translate(-50%, -50%)",
      xs:"translate(-41%, -50%)",
    },
    width: {
      xl:500,
      lg:500,
      md:500,
      sm:500,
      xs:300
    },
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    if(note) {
      console.log('note in createnote dynamic', note)
      setDescription(note.description)
      setId(note._id)
    }
  }, [note]);

  const ajouterNouvelleNote = async () => {
    if (!description) {
      champsvide();

    } else {
      try{
        const date_create = new Date();
        await ajouterNote(description, ownerId, date_create, ownerType, id);
        successtoast();
        closeIt();

      }catch(error){
        toaster.notifyError("insertion KO : " + error.message)
      }
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Box sx={style}>
        <h4 style={{ marginBottom: 20 }}>Ajouter une note</h4>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { my: 1, maxWidth: "436px" },
          }}
          noValidate
          autoComplete="off"
        >
          <textarea
            required
            id="outlined-required"
            label="Description"
            style={{width : "100%"}}
            rows={4}
            size="medium"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end", pt: 2 }}>
            <Button
              sx={{
                background: "#757575",
                color: "#fff",
                "&:hover": { background: "#757575" },
                mr: 2,
              }}
              onClick={closeIt}
            >
              Annuler
            </Button>
            <Button
              sx={{
                background: "#299CDB",
                color: "#fff",
                "&:hover": { background: "#299CDB" },
              }}
              onClick={ajouterNouvelleNote}
            >
              Ajouter
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CreateNoteDynamic;
