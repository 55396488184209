import React, { useEffect, useState } from "react";
import { Box, Autocomplete, Button, CircularProgress } from "@mui/material";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers";
 
import { ToastContainer } from "react-toastify";
import { userService } from "../../../service/UserService";
import { TacheService } from "../../../service/crm/TacheService";
import { useToast } from "../../../Context/ToastContext";
import { FieldValidationError } from "../../../errors/FieldValidationError";
import { useContact } from "../../../Context/crm/ContactContext";
 
import moment from "moment/moment";
import dayjs from "dayjs";
import PropTypes from "prop-types";


const CreateTask = ({ closeIt , affaire, task}) => {
  const [objectif, setObjectif] = useState('');
  const [id, setId] = useState(null);
  const [description, setDescription] = useState('');
  const [dateEcheance, setDateEcheance] = useState("");
  const [assigned, setAssigned] = useState({label: "", value: null});
  const [status, setStatus] = useState("A faire");
  const [errors, setErrors] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isPageLoading, setIsPageLoading] = useState(true)

  const {fetchContactData} = useContact()
  const toaster = useToast()
  const tacheService = new TacheService()
  const statuts = [
    {
      label: "En cours",
      value: "En cours",
    },
    {
      label: "A faire",
      value: "A faire",
    },
    {
      label: "Terminée",
      value: "Terminée",
    }
  ];
  useEffect(() => {

    fetchUsers().then(response => setIsPageLoading(false))

  }, []);

  useEffect(() => {
    setId((task?._id) ?? id)
    setDescription(task?.description ?? description)

    if(task?.dueDate) {
      handleDateChange(dayjs(moment(new Date(task.dueDate)).format("YYYY-MM-DD")))

    }

    setObjectif(task?.goal ?? objectif)

    if(task?.status){
      const matchedStatuses = statuts.filter((statusOption) => statusOption.value === task.status)
      if(matchedStatuses.length > 0){
        const matchedStatus = matchedStatuses.shift()
        setAssigned({label: matchedStatus.label, value: matchedStatus.value})
      }
    }



  }, [])

  const fetchUsers = async () => {
    const usersResponseData = await userService.getAllUsers()

    console.log('usersResponseData', usersResponseData)
    const users = usersResponseData?.data?.results ?? []
    const newUserOptions = []
    users.map((user) => {
      newUserOptions.push( {
        label: user._source.firstname,
        value: user._source.id
      })
    })


    if(task?.assignedUserId){
      const matchedAssignedUsers = newUserOptions.filter((userOption) => userOption.value === task.assignedUserId)
      if(matchedAssignedUsers.length > 0){
        const matchingAssignedUser = matchedAssignedUsers.shift()
        console.log("matchingAssignedUser", matchingAssignedUser)
        setAssigned({label: matchingAssignedUser.label, value: matchingAssignedUser.value})
      }
    }

  }

  //Toasts
  const successtoast = () => toaster.notifySuccess(getToastMesssage);

  const handleDateChange = (date) => {
    console.log("date in handleDateChange", date)
    setDateEcheance(date);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: {
      xl:"translate(-50%, -50%)",
      lg:"translate(-50%, -50%)",
      md:"translate(-50%, -50%)",
      sm:"translate(-50%, -50%)",
      xs:"translate(-41%, -50%)",
    },
    width: {
      xl:500,
      lg:500,
      md:500,
      sm:500,
      xs:300
    },
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const getToastMesssage = () => {
    return (!task) ? "Tâche crée avec succès" : "Tâche modifiée avec succès"
  }

  const ajouterNouvelleTask = async () => {
    try{
      setIsLoading(true)
      const taskRequest = buildTaskRequest()
      console.log('taskRequest', taskRequest)
      
      //update opportunities data to avoid refetch from WS
      await tacheService.createTask(taskRequest)
      fetchContactData(affaire.contact._id ?? affaire.contact)
      successtoast();
      setIsLoading(false)
      closeIt();

    }catch(error){
      if(error instanceof FieldValidationError){
        setErrors(error.data)
        toaster.notifyError('verifier les champs')

      }else{
        toaster.notifyError('erreur lors de l\'enregistrement : ' + error.message)
      }
    }
    
    setIsLoading(false)
  };

  const buildTaskRequest = () => {
    console.log("affaire inside buildTaskRequest", affaire)
    return {
      _id: id,
      opportunity_id: affaire._id,
      goal: objectif,
      description: description,
      due_date: dateEcheance,
      assigned_user_id: assigned.value,
      status: status
    }
  }
  
  const minDate = dayjs();

  return (
    <>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Box sx={style}>
        {(isPageLoading) ? <CircularProgress color="secondary" /> : <>
        <h4 style={{ marginBottom: 20 }}>Ajouter une tâche</h4>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { my: 1, maxWidth: "436px" },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            required
            id="outlined-required"
            label="Objectif"
            fullWidth
            value={objectif}
            onChange={(e) => setObjectif(e.target.value)}
          />
            {errors.goal && <p style={{color: 'red'}}>{errors.goal}</p>}
          <DatePicker
            label="Date d'échéance"
            value={dateEcheance ? dateEcheance : null}
            onChange={handleDateChange}
            renderInput={(params) => <TextField {...params} />}
            sx={{
              width: "100%",
            }}
            minDate={minDate}
          />
            {errors.due_date && <p style={{color: 'red'}}>{errors.due_date}</p>}

          <Autocomplete
            disablePortal
            id="combo-box-demo"
            sx={{ width: "100%" }}
            renderInput={(params) => <TextField {...params} label="Statut" />}
            fullWidth
            options={statuts}
            value={status}
            onChange={(event, newValue) => {
              setStatus(newValue ? newValue.value : null);
            }}
            isOptionEqualToValue={(option, value) => option.value === value}
          />
            {errors.status && <p style={{color: 'red'}}>{errors.status}</p>}
            <label htmlFor={'textareaId'}
                style={{width: "100%"}}>
                Description
            </label>
            <textarea
              id="textareaId"
              style={{width: "100%"}}
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
              {errors.description && <p style={{color: 'red'}}>{errors.description}</p>}

          <Box sx={{ display: "flex", justifyContent: "flex-end", pt: 2 }}>
            <Button
              sx={{
                background: "#757575",
                color: "#fff",
                "&:hover": { background: "#757575" },
                mr: 2,
              }}
              onClick={closeIt}
            >
              Annuler
            </Button>
            {isLoading ? (
            <CircularProgress color="secondary" />
                  ) : (
            <Button
              sx={{
                background: "#299CDB",
                color: "#fff",
                "&:hover": { background: "#299CDB" },
              }}
              onClick={ajouterNouvelleTask}
            >
              {(!task) ? 'Ajouter' : 'Modifier'}
            </Button>
            )}
          </Box>
        </Box>
        </>
        }
      </Box>
    </>
  );
};

CreateTask.propTypes = {
  closeIt: PropTypes.func.isRequired,
  affaire: PropTypes.any,
  task: PropTypes.any
}

export default CreateTask;
