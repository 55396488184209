import React, { useState, useEffect } from "react";
import { Box, Typography, Card, Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Modal from "@mui/material/Modal";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import CreateNoteDynamic from "./CreateNoteDynamic";
import { useToast } from "../Context/ToastContext";
import { AppCustomConfirmationDialog } from "./AppCustomConfirmationDialog";
import { noteService } from "../service/NoteService";
import CustomBtn from "./customBtns/CustomBtn";
import PropTypes from "prop-types";

const NoteDynamic = ({ notes = [], onUpdate, addPaperStyle, createNote }) => {
  const noteData = notes;
  const [openDeleteNote, setOpenDeleteNote] = useState(false);
  const [openUpdateNote, setOpenUpdateNote] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const toaster = useToast();

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleCreateNote = () => {
    if (createNote) {
      createNote();
    } else {
      toaster.notifyError("La fonction de création de note n'est pas définie.");
    }
  };
  const handleOpenDeleteNote = (note) => {
    setSelectedNote(note);
    setOpenDeleteNote(true);
  };

  const handleOpenUpdateNote = (note) => {
    setSelectedNote(note);
    setOpenUpdateNote(true);
  };

  const handleDeleteNote = async () => {
    try {
      if (!selectedNote) throw new Error("auccune note selectionné");

      const isDeleted = await noteService
        .deleteNote(selectedNote._id)
        .catch((error) => {
          throw error;
        });

      if (!isDeleted) throw new Error("impossible de supprimer la note");

      setSelectedNote(undefined);
      onUpdate();
    } catch (error) {
      toaster.notifyError(error.message);
    }
  };

  const onCloseUpdateNote = () => {
    setSelectedNote(undefined);
    setOpenUpdateNote(false);
    onUpdate();
  };

  const wrapInMobile = {
    flexWrap: {
      xl: "nowrap",
      lg: "nowrap",
      md: "nowrap",
      sm: "nowrap",
      xs: "wrap",
    },
  };

  const mobileNoMargin = {
    marginLeft: {
      xl: "30px",
      lg: "30px",
      md: "30px",
      sm: "30px",
      xs: "0",
    },
  };
  const btnsWidth = {
    width: {
      xl: "auto",
      lg: "auto",
      md: "auto",
      sm: "auto",
      xs: "100%",
    },
  };

  NoteDynamic.propTypes = {
    notes: PropTypes.arrayOf(
      PropTypes.shape({
        createdAt: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        _id: PropTypes.string.isRequired,
        landId: PropTypes.string,
      })
    ),
    onUpdate: PropTypes.func.isRequired,
    addPaperStyle: PropTypes.string,
    createNote: PropTypes.func,
  };

  return (
    <Box className={addPaperStyle + " Layout bg-white my-4 rounded"}>
      <Box
        sx={{ display: "flex", alignItems: "center"}}
      >
        <h3>Notes</h3>
        <Box
          sx={{
            marginLeft: "16px",
            cursor: "pointer",
            fontSize: "24px",
            width: "24px",
            height: "24px",
            background: "rgb(41, 156, 219)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "100%",
            color: "#fff",
            mb: ".5rem"
          }}
          onClick={handleCreateNote}
        >
          +
        </Box>
      </Box>
      {noteData && noteData.length > 0 ? (
        noteData.map((notes, index) => (
          <Card
            key={`${notes.createdAt}-${notes.description}`}
            sx={{
              background: index % 2 === 0 ? "#f4fafd" : "#ffffff",
            }}
            className="mb-2"
          >
            <Box className="noteCordion">
              <Box sx={{ display: "flex", width: "auto", ...wrapInMobile }}>
                <Box>{formatDate(notes.createdAt)}</Box>
                <Box
                  sx={{
                    maxWidth: "891px",
                    ...mobileNoMargin,
                    wordWrap: "anywhere",
                  }}
                >
                  {notes.description}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  ...btnsWidth,
                  justifyContent: "flex-end",
                }}
              >
                <Box>
                  {/* <ModeEditIcon sx={{ mr: 2 }} />*/}
                  <Button onClick={() => handleOpenUpdateNote(notes)}>
                    <ModeEditIcon sx={{ color: "blue" }} />
                  </Button>
                </Box>
                <Box>
                  {/* <ModeEditIcon sx={{ mr: 2 }} />*/}
                  <Button onClick={() => handleOpenDeleteNote(notes)}>
                    <DeleteIcon sx={{ color: "red" }} />
                  </Button>
                </Box>
              </Box>
            </Box>
            <AppCustomConfirmationDialog
              open={openDeleteNote}
              onAccept={handleDeleteNote}
              onClose={() => setOpenDeleteNote(false)}
              onDecline={() => setOpenDeleteNote(false)}
              bodyMessage={"Souhaitez-vous supprimer définitivement la note?"}
              title={"Suppression note"}
            />
          </Card>
        ))
      ) : (
        <Typography sx={{ p: 3 }}>Pas de note disponible</Typography>
      )}

      <Modal
        open={openUpdateNote}
        onClose={onCloseUpdateNote}
        aria-labelledby="modification-note"
        aria-describedby="modification-note"
      >
        {selectedNote ? (
          <CreateNoteDynamic
            note={selectedNote}
            closeIt={onCloseUpdateNote}
            ownerId={selectedNote.landId}
            ownerType={"land"}
          ></CreateNoteDynamic>
        ) : (
          <></>
        )}
      </Modal>
    </Box>
  );
};

export default NoteDynamic;
