import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const InfoModal = (props) => {
  const {show, onHide, data} = props;
  const { t } = useTranslation();
  

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">{data.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p style={data.error ? { color: 'red' } : { color: 'black' }}>
          {t(data.content)}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => onHide({ ...data, show: false })}>{t('Fermer')}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InfoModal;
