import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import { Box, CircularProgress, Typography } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { LandsMapFilterCard } from "../component/LandsMapFilterCard";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { ViewList } from "@mui/icons-material";
import CustomBtn from "../component/customBtns/CustomBtn";
import { API_URL } from "@utils/constants";
import { useToast } from "../Context/ToastContext";
import jwtInterceptor from "../service/jwtInterceptor";
import { LandsMap } from "../component/maps/LandsMap";

const MapsContainer = (props) => {
  const DEFAULT_PER_PAGE = 1000;
  const DEFAULT_PAGE = 0;
  const [dataTable, setDataTable] = useState([]);
  const [loading, setLoading] = useState(false);
  const toaster = useToast();
  const [isError, setIsError] = useState(false);

  const [filters, setFilters] = useState({
    parcelNumber: "",
    commune: "",
    address: "",
    zone: "",
    owner: "",
    surface: "",
    constructionDate: "",
  });

  const mapsLayout = {
    display: "block",
    height: "100%",
    width: "100%",
    position: "absolute",

  };

  const switchBtn = {
    position: "fixed",
    top: "2%",
    right: "1%",
    zIndex:9999
  };

  const searchfn = async (filter) => {

    if(filter.isEmpty) {
      setLoading(true);

      setDataTable([])
      setIsError(false);
      setLoading(false);
      return
    }
    setLoading(true);
    filter.page = DEFAULT_PAGE
    filter.size = DEFAULT_PER_PAGE

    setFilters(filter);
    return await jwtInterceptor
      .post(`${API_URL}/lands/search`, filter)
      .then((res) => {
        setDataTable(res.data.data.results);
        setLoading(false);
        console.log('res.data.data.results', res.data.data.results)
        if(!(res.data.data.results).length > 0 ){
          setIsError(true);
          console.log('true est true')
        }
        else{
          console.log('false est true')
          setIsError(false);
        }
      })
      .catch((error) => {
        toaster.notifyError(error.message);

      })
      .finally(() => {
        setLoading(false);
      })
  };

  return (
    <Box className="w-100" sx={{ height: "101vh", position: "relative" }}>
      <Box className="maps-layout" sx={mapsLayout}>
      {loading ? (<Box sx={{background: '#fff', width: '150px' , height: '150px', zIndex: '999999999', display: "flex", position:"absolute", left:"50%" , top:"50%",transform: 'translate(-50%, -50%)', justifyContent: "center", alignItems: "center", flexWrap: "wrap", borderRadius: "4px",boxShadow: "#9b9b9b 0px 0px 7px -3px;" }}><Box><Box sx={{width:"100%", display: "flex", justifyContent: "center", alignItems: "center"}}><CircularProgress color="secondary" sx={{fontSize: "20px"}} /></Box><Typography>Chargement...</Typography></Box></Box>) : (<></>)}
      {isError ? (<Box sx={{background: '#fff', width: '150px' , height: '150px', zIndex: '999999999', display: "flex", position:"absolute", left:"50%" , top:"50%",transform: 'translate(-50%, -50%)', justifyContent: "center", alignItems: "center", flexWrap: "wrap", borderRadius: "4px",boxShadow: "#9b9b9b 0px 0px 7px -3px;" }}><Box><Typography sx={{textAlign: 'center'}}>Aucun résultat trouvé</Typography></Box></Box>) : (<></>)}
        <LandsMap lands={dataTable}></LandsMap>
        <Nav.Link as={Link} to="/land" state={filters}>
          <CustomBtn
            label="Liste"
            bgColor="#299CDB"
            iconBg={"#3ba9e5"}
            icon={<ViewList />}
            styles={{ float: "right", ...switchBtn }}
          />
        </Nav.Link>
      </Box>
      <Helmet>
        <title>Maps - Mixdata</title>
      </Helmet>
      <Grid container spacing={2} sx={{ height: "100%", position: isError || loading ? 'relative' : 'revert', zIndex:  '99999999999' }}>
        <Grid
          className="filters shadow-none"
          xl={3}
          sx={{ position: "absolute" }}
        >
          <Paper className="shadow-none  m-4" style={{ height: "auto" }}>
            <LandsMapFilterCard handleSearch={(filters) => searchfn(filters)} />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MapsContainer;
